import * as React from "react"
import {navigate} from "gatsby"
import {useEffect, useState} from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import {isLoggedIn} from "../utils/auth.service";
import LoadingSpinner from "../components/loading-spinner";
import authHeader from "../utils/auth-header";
import PayoutHistoryList from "../components/payout-history/list";



const PayoutHistory = () => {

  const [loading, setLoading] = useState(false);
  const [payoutHistory, setPayoutHistory] = useState([]);

  useEffect(() => {

    if(!isLoggedIn()){
     return navigate('/');
    }

      loadOrders();


  }, [])


    const loadOrders = () => {

        setLoading(true);

        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };



        fetch(process.env.WASHCAR_API_URL + "/partners/payout-history", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setPayoutHistory(resultData.data.payout_history);
                setLoading(false);
            }) //


    }



  return (
    <Layout>

      <Seo title="Izmaksas Vēsture" />
      <h1 className="title">Izmaksas vēsture</h1>
      {loading ? <div className="pos-center"><LoadingSpinner /></div> : <PayoutHistoryList payoutHistory={payoutHistory} />}

    </Layout>
  )
}

export default PayoutHistory

/*
({order.profit_percent}%)
 */