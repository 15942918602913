import React from "react";
import {convertPrice} from "../../utils/helper.service";

const PayoutHistoryList = (props) => {

    return (

        props.payoutHistory.length === 0 ? <div className={'notification'}>Vēl nav veikta neviena izmaksa.</div> :

            <div className={'box'}>
                <div className="list">

                    {props.payoutHistory.map(payoutItem => (

                        <div key={payoutItem.id} className="list-item">
                            <div className="list-item-content">
                                <div
                                    className="list-item-title">Izņemts: <b>{convertPrice(payoutItem.amount_in_cents)} </b>
                                </div>
                                <div className="list-item-description">
                                    <div className="tag is-rounded ml-1">Datums: <b>{payoutItem.transfer_at}</b></div>
                                </div>

                                <div className="list-item-description">
                                    <div className="tag is-rounded ml-1">Veids: <b>{payoutItem.stripe_transfer_id === null ?  'WashCar kredīti' : 'bankas pārskaitījums'}</b></div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
    );
};

export default PayoutHistoryList;
